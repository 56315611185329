.carousel-containerUno {
    position: relative;
    width: 100%;
    max-width: 70%; /* Ajusta el ancho máximo según sea necesario */
    margin: 0 auto;
  }
  
  .imageUno {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .prevUno,
  .nextUno {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 24px;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .prevUno {
    left: 0;
  }
  
  .nextUno {
    right: 0;
  }