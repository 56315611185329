.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }  
 

  
  .prev,
  .next {
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 24px;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .prev {
    margin-right: 1%;
  }
  
  .next {
    margin-left: 5%;
  }
  
  .carousel-images {
    display: flex;
  }  
  

  .image {
    width: 25%;
    height: auto;
    margin-right: 2%;
    cursor: pointer;
  }
  
  .image::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: transparent; /* Color inicial */
    transition: background-color 0.3s ease;
  }
  
  .image.active::after {
    background-color: blue; /* Azul al hacer clic */
  }
  
  .image:hover::after {
    background-color: #ffcc00; /* Mostaza al pasar el cursor */
  }