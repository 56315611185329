.tabs {
    width: 100%;
  }
  
  .tab-list {
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  
  .tab-button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tab-button.active {
    border-bottom: 2px solid #007bff;
    font-weight: bold;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    border-top: none;
  }