.button-group-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Margen superior */
  margin-bottom: 20px; /* Margen inferior */
}

.button-group {
  display: flex;
}

.button {
  margin-right: 10px; /* Espacio entre botones */
  background-color: transparent; /* Elimina el color de fondo */
  border: none; /* Elimina el contorno */
  text-transform: none; /* Mantén el texto original */
  position: relative; /* Permite posicionar el pseudo-elemento */
  cursor: pointer; /* Cambia el cursor a una mano al pasar sobre el botón */
  font-size: 25px;
}

.button::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px; /* Altura del color de la parte inferior */
  background-color: transparent; /* Color inicial */
  transition: background-color 0.3s ease; /* Transición suave del color de fondo */
}

.button.clicked::after {
  background-color: #1976d2; /* Cambia el color de la parte inferior cuando se hace clic en el botón */
}

.button.hovered::after {
  background-color: #ffc107; /* Cambia el color de la parte inferior cuando se pasa el cursor sobre el botón */
}
